<template>
    <div>
        <v-container fluid>

            <div class="main">
                <v-row justify="center">
                    <v-col cols="12" lg="8">

                        <v-card-title class="pl-0 pr-0">

                            <v-icon v-if="!id" color="primary" left>mdi-plus-circle</v-icon>
                            <v-icon v-if="id" color="primary" left>mdi-content-duplicate</v-icon>
                            <span v-if="!id"> Ajouter une campagne</span>
                            <span v-if="id"> Dupliquer une campagne</span>

                            <v-spacer/>

                            <v-btn color="primary" dark depressed fab small to="/campaigns">
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>

                        </v-card-title>

                        <v-card :disabled="duplicateLoading"
                                :loading="duplicateLoading"
                                class="rounded-lg shadow">


                            <v-stepper v-model="step" flat>
                                <v-stepper-header class="elevation-0">
                                    <v-stepper-step :complete="step > 1" step="1">
                                        Info & Planification
                                    </v-stepper-step>

                                    <v-divider/>

                                    <v-stepper-step :complete="step > 2" step="2">
                                        BDD & Montant
                                    </v-stepper-step>

                                    <v-divider/>

                                    <v-stepper-step :complete="step > 3" step="3">
                                        SMS
                                    </v-stepper-step>

                                </v-stepper-header>

                                <v-divider/>

                                <v-stepper-items>

                                    <v-stepper-content step="1">
                                        <v-card>

                                            <v-card-text>

                                                <v-text-field v-model.trim="form.title"
                                                              :error-messages="errors.title"
                                                              label="Titre *"/>

                                                <v-row>
                                                    <v-col>
                                                        <v-menu
                                                                v-model="dateMenu"
                                                                :close-on-content-click="false"
                                                                :nudge-right="40"
                                                                min-width="auto"
                                                                offset-y
                                                                transition="scale-transition"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                        v-model="form.scheduled_date"
                                                                        label="Date d'envoi *"
                                                                        prepend-inner-icon="mdi-calendar-outline"
                                                                        readonly
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="form.scheduled_date"
                                                                           :min="form.scheduled_date"
                                                                           @input="dateMenu = false"
                                                            ></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col>
                                                        <v-menu
                                                                ref="menu"
                                                                v-model="timeMenu"
                                                                :close-on-content-click="false"
                                                                :nudge-right="40"
                                                                :return-value.sync="form.scheduled_time"
                                                                min-width="auto"
                                                                offset-y
                                                                transition="scale-transition"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                        v-model="form.scheduled_time"
                                                                        label="Heur d'envoi *"
                                                                        prepend-inner-icon="mdi-clock-outline"
                                                                        readonly
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-time-picker v-model="form.scheduled_time"
                                                                           @click:minute="$refs.menu.save(form.scheduled_time)"
                                                            ></v-time-picker>
                                                        </v-menu>
                                                    </v-col>
                                                </v-row>

                                                <v-alert v-if="errors && errors.scheduled_datetime" dense type="error">
                                                    {{ errors.scheduled_datetime[0] }}
                                                </v-alert>

                                                <v-file-input ref="image"
                                                              :clearable="false"
                                                              :disabled="fileLoading"
                                                              :error-messages="fileErrors.image"
                                                              :loading="fileLoading"
                                                              :prepend-icon="null"
                                                              accept="image/*"
                                                              chips
                                                              counter
                                                              label="Sélectionnez une image à afficher sur l'application mobile"
                                                              show-size
                                                              small-chips
                                                              @change="handleUploadFile($event)"
                                                >
                                                    <v-icon slot="prepend-inner" color="gifty">
                                                        mdi-image
                                                    </v-icon>
                                                </v-file-input>

                                                <div v-if="form.image"
                                                     class="d-flex justify-space-between pa-3 rounded grey lighten-4">
                                                    <v-avatar size="40">
                                                        <v-img :src="fileUrl + form.image" class="rounded"/>
                                                    </v-avatar>
                                                    <v-spacer/>
                                                    <v-icon color="red" @click="resetImage()">mdi-close-circle
                                                    </v-icon>
                                                    <v-icon color="success">mdi-check-circle</v-icon>
                                                </div>

                                                <v-text-field v-model="form.youtube_link"
                                                              :error-messages="errors.youtube_link"
                                                              label="Entrez la clé vidéo youtube"
                                                              placeholder="Exemple : watch?v=(ThutMddh32E)">
                                                    <v-icon slot="prepend-inner" color="gifty">
                                                        mdi-youtube
                                                    </v-icon>
                                                </v-text-field>

                                                <v-alert border="left"
                                                         dense
                                                         prominent
                                                         text
                                                         type="info">
                                                                            <span class="font-weight-bold pointer">
                                                                              <Copy text="@person"/>
                                                                            </span>
                                                    Est remplacé par le nom de la personne
                                                </v-alert>


                                                <v-textarea v-model.trim="form.message"
                                                            :error-messages="errors.message"
                                                            clearable
                                                            label="Tapez ici un message qui affiche sur l'application mobile *"
                                                            outlined
                                                            prepend-inner-icon="mdi-message-outline"
                                                            rows="3">
                                                </v-textarea>

                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer/>
                                                <v-btn :dark="!(!form.title || !form.message)"
                                                       :disabled="!form.title || !form.message"
                                                       color="primary"
                                                       depressed
                                                       @click="step = 2">
                                                    Continuer
                                                    <v-icon right>mdi-arrow-right</v-icon>
                                                </v-btn>
                                            </v-card-actions>

                                        </v-card>
                                    </v-stepper-content>

                                    <v-stepper-content step="2">
                                        <v-card>

                                            <v-card-text>
                                                <v-text-field v-model.trim="form.amount"
                                                              v-number
                                                              :error-messages="errors.amount"
                                                              label="Montant *"
                                                              suffix="DZD"/>

                                                <v-autocomplete v-model="form.database_id"
                                                                :disabled="isDBLoading"
                                                                :error-messages="errors.database_id"
                                                                :items="databases"
                                                                :loading="isDBLoading"
                                                                item-text="name"
                                                                item-value="id"
                                                                label="Sélectionnez une base de données *"
                                                />
                                                <v-radio-group v-model="form.type" label="Type des SMS *">
                                                    <v-radio
                                                            v-for="(type,i) in types"
                                                            :key="i"
                                                            :label="type.name"
                                                            :value="type.value"
                                                    ></v-radio>
                                                </v-radio-group>
                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer/>
                                                <v-btn color="primary" text @click="step = 1">
                                                    <v-icon left>mdi-arrow-left</v-icon>
                                                    Retour
                                                </v-btn>
                                                <v-btn :dark="!(!form.amount || !form.database_id)"
                                                       :disabled="!form.amount || !form.database_id"
                                                       color="primary"
                                                       depressed
                                                       @click="step = 3">
                                                    Continuer
                                                    <v-icon right>mdi-arrow-right</v-icon>
                                                </v-btn>
                                            </v-card-actions>

                                        </v-card>
                                    </v-stepper-content>

                                    <v-stepper-content step="3">
                                        <v-card>

                                            <v-card-text>

                                                <v-alert border="left"
                                                         dense
                                                         prominent
                                                         text
                                                         type="info">
                                                                            <span class="font-weight-bold pointer">
                                                                              <Copy text="@person"/>
                                                                            </span>
                                                    Est remplacé par le nom de la personne
                                                </v-alert>

                                                <v-textarea v-model.trim="form.sms"
                                                            :error-messages="errors.sms"
                                                            class="sms"
                                                            clearable
                                                            counter
                                                            label="Écrivez un SMS ici *"
                                                            outlined
                                                            prepend-inner-icon="mdi-cellphone-message"
                                                            rows="3"></v-textarea>
                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer/>
                                                <v-btn color="primary" text @click="step = 2">
                                                    <v-icon left>mdi-arrow-left</v-icon>
                                                    Retour
                                                </v-btn>
                                                <v-btn :dark="!(!form.sms || !form.message)"
                                                       :disabled="!form.sms || !form.message"
                                                       color="primary"
                                                       depressed
                                                       @click="checkCost">
                                                    <v-icon left>mdi-send</v-icon>
                                                    Envoyer
                                                </v-btn>
                                            </v-card-actions>

                                        </v-card>
                                    </v-stepper-content>

                                </v-stepper-items>
                            </v-stepper>

                        </v-card>
                    </v-col>
                </v-row>
            </div>


            <v-dialog v-model="dialog"
                      persistent
                      scrollable
                      width="500">
                <v-card :disabled="isLoading || costLoading"
                        :loading="isLoading || costLoading">

                    <v-card-title>
                        <v-spacer/>
                        <v-icon @click="dialog = false">mdi-close</v-icon>
                    </v-card-title>

                    <v-card-text v-if="costLoading">
                        <v-skeleton-loader type="avatar,article,article"/>
                    </v-card-text>

                    <v-card-text v-else class="text-center">

                        <v-avatar color="gifty" size="80">
                            <v-icon dark size="40">mdi-information</v-icon>
                        </v-avatar>

                        <h2 class="py-5">CONFIRMATION</h2>

                        <p>
                            <span class="font-weight-medium">Total SMS :</span>
                            {{ analytic.total_sms }}
                        </p>

                        <p>
                            <span class="font-weight-medium">Coût total des SMS :</span>
                            {{ CurrencyFormatting(analytic.cost_sms) }} DZD
                        </p>

                        <p>
                            <span class="font-weight-medium">Total solde à envoyer :</span>
                            {{ CurrencyFormatting(analytic.amount_total) }} DZD
                        </p>

                        <p>
                            <span class="font-weight-medium">Coût total de l’operation :</span>
                            {{ CurrencyFormatting(analytic.amount_total + analytic.cost_sms) }} DZD
                        </p>

                        <p class="primary--text font-weight-medium">
                            <span>Votre solde actuel :</span>
                            {{ CurrencyFormatting(analytic.balance) }} DZD
                        </p>

                        <p class="info--text font-weight-medium">
                            <span>Solde restant aprés l’opération :</span>
                            {{ CurrencyFormatting(remainingBalance()) }} DZD
                        </p>

                        <v-alert v-if="remainingBalance() < 0"
                                 prominent
                                 text
                                 type="info">
                            Vous ne pouvez pas créer cette campagne car son solde total dépasse votre solde actuel.
                        </v-alert>

                    </v-card-text>

                    <v-card-actions v-if="!costLoading">
                        <v-spacer/>
                        <v-btn color="gifty" text @click="dialog=false">
                            Fermer
                        </v-btn>

                        <v-btn :dark="remainingBalance()>=0"
                               :disabled="remainingBalance() < 0"
                               :loading="isLoading"
                               color="gifty"
                               depressed
                               @click="save">
                            Valider
                            <v-icon right>mdi-check</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-container>
    </div>
</template>

<script>
import {HTTP} from "@/http-common";
import moment from 'moment'

export default {
    data() {
        return {
            id: null,
            duplicateLoading: false,
            step: 1,
            isDBLoading: false,
            isLoading: false,
            dateMenu: false,
            timeMenu: false,
            form: {
                title: null,
                amount: null,
                type: 'all',
                sms: "Bonjour @person, bienvenue sur l'application GIFTY",
                message: 'Bonjour @person, bienvenue sur l\'application GIFTY',
                image: null,
                youtube_link: null,
                database_id: null,
                scheduled_date: moment().format('YYYY-MM-DD'),
                scheduled_time: moment().add(10, 'minutes').format('HH:mm'),
            },
            errors: {},
            databases: [],
            dialog: false,
            costLoading: false,
            analytic: {
                total_sms: 0,
                cost_sms: 0,
                amount_total: 0,
                balance: 0
            },

            fileUrl: process.env.VUE_APP_FILE_URL,
            fileLoading: false,
            fileErrors: [],
        };
    },
    methods: {
        save() {
            this.isLoading = true
            let data = this.form
            data['scheduled_datetime'] = this.form.scheduled_date + ' ' + this.form.scheduled_time + ':00'
            HTTP.post('/v1/campaigns/store', this.form).then(() => {
                this.isLoading = false
                this.$successMessage = "Données de campagne enregistrées avec succès"
                this.$store.commit('setUserBalance', this.remainingBalance())
                this.$router.push('/campaigns')
            }).catch(err => {
                this.isLoading = false
                this.dialog = false
                this.errors = err.response.data.errors
                this.$errorMessage = "Une erreur s'est produite veuillez corriger."
                this.$vuetify.goTo(0)
                console.log(err)
            })
        },
        getDatabases() {
            this.isDBLoading = true
            HTTP.get('/v1/users/databases').then(res => {
                this.isDBLoading = false
                this.databases = res.data.data
            }).catch(err => {
                this.isDBLoading = false
                console.log(err)
            })
        },
        checkCost() {
            this.dialog = true
            this.costLoading = true
            HTTP.get('/v1/campaigns/check-cost', {
                params: {
                    database_id: this.form.database_id,
                    type: this.form.type,
                    amount: this.form.amount,
                    sms: this.form.sms,
                }
            }).then(res => {
                this.costLoading = false
                this.analytic = res.data.data
            }).catch(err => {
                this.isDBLoading = false
                this.dialog = false
                this.errors = err.response.data.errors
                this.$errorMessage = "Une erreur s'est produite veuillez corriger."
                this.$vuetify.goTo(0)
                console.log(err)
            })
        },
        handleUploadFile(file) {
            if (file) {
                this.fileLoading = true
                let formData = new FormData()
                formData.append('image', file)
                HTTP.post('/v1/campaigns/upload', formData).then((res) => {
                    this.form.image = res.data
                    this.fileLoading = false
                }).catch((err) => {
                    this.fileErrors = err.response.data.errors
                    this.$errorMessage = "Une erreur s'est produite veuillez corriger."
                    this.fileLoading = false
                    console.log(err)
                })
            }
        },
        resetImage() {
            this.form.image = null
            this.$refs.image.reset()
        },
        remainingBalance() {
            return this.analytic.balance - (this.analytic.amount_total + this.analytic.cost_sms)
        },
        getCampaignById() {
            this.duplicateLoading = true
            HTTP.get('/v1/campaigns/' + this.id + '/show').then(res => {
                this.duplicateLoading = false
                if (res.data.data) {
                    let data = res.data.data
                    this.form = {
                        title: data.title,
                        amount: data.amount,
                        type: data.type,
                        sms: data.sms,
                        message: data.message,
                        image: data.image,
                        youtube_link: data.youtube_link,
                        database_id: data.database_id,
                        scheduled_date: moment().format('YYYY-MM-DD'),
                        scheduled_time: moment().add(10, 'minutes').format('HH:mm'),
                    }
                } else {
                    this.$router.push('/404')
                }
            }).catch(err => {
                this.duplicateLoading = false
                console.log(err)
            })
        }
    },
    created() {
        this.getDatabases()
        if (this.$route.params.id) {
            this.id = this.$route.params.id
            this.getCampaignById()
        }
    },
    computed: {
        types() {
            return [
                {name: 'Envoyer a tous le monde', value: 'all'},
                {name: 'Envoyer seulement au person qui non pas de compte GIFTY', value: 'gifty'},
            ]
        }
    }
};
</script>

<style>
.sms .v-counter {
    background-color: #ffab02;
    padding: 5px;
    border-radius: 5px;
    color: #fff !important;
}

</style>
